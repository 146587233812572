import ng from 'angular';

//@ngInject
function acpAcquisitionModel(
  $log,
  $state,
  $timeout,
  $window,
  nsStorage,
  acpAcquisitionUrlService,
  acpDeviceService,
  acpConfigurationService,
  acpAttributionService,
  acpInAppBrowserModel,
  iovationModel,
  acpFrontendConfigModel,
  appsflyer
) {
  var inAppBrowserInstance;
  var windowInstance;

  function onAcquisitionAuthentication(accessToken) {
    $log.debug('Logging in using access token:', accessToken);

    var hash = $window.btoa(ng.toJson({ access_token: accessToken }));
    $state.go('login-authorize', { '#': hash });
    closeAcquisition();
  }

  function closeAcquisition() {
    if (inAppBrowserInstance) {
      inAppBrowserInstance.close();
      inAppBrowserInstance = null;
    }

    if (windowInstance) {
      windowInstance.close();
      windowInstance = null;
    }
  }

  function onAppsflyerEvent(data) {
    if (data.referralCode) {
      appsflyer.setUserId(data.referralCode);
    }
    appsflyer.handleAFEvent(data.event, data);
  }

  function openAcquisition(options, inAppBrowserOptions, acquisitionURL) {
    // in case there are any lingering instances
    closeAcquisition();

    if (!acquisitionURL) {
      $log.warn('No acquisition URL defined');
      return;
    }

    $log.debug('Acquiring customer with', acquisitionURL);

    if (acpDeviceService.isWeb()) {
      windowInstance = $window.open(acquisitionURL, '_blank');
      windowInstance.expiredTokenBailout = closeAcquisition;

      if (options.acp_access_token) {
        nsStorage.local('access_token', options.acp_access_token);

        // If Hub user (the options have acp_access_token property) we want to close the acquisition window
        // and authenticate to new account in original window
        windowInstance.acpLoginHandler = onAcquisitionAuthentication;
      }
    } else {
      if (acpConfigurationService.getAcquistionInAppBrowserToolbarColor()) {
        inAppBrowserOptions.toolbarcolor = acpConfigurationService.getAcquistionInAppBrowserToolbarColor();
      }
      if (
        acpConfigurationService.getAcquistionInAppBrowserToolbarTextDisabled()
      ) {
        inAppBrowserOptions.headertext = '';
      }
      if (
        acpConfigurationService.getAcquistionInAppBrowserToolbarTranslucentDisabled()
      ) {
        inAppBrowserOptions.toolbartranslucent = 'no';
      }

      inAppBrowserInstance = acpInAppBrowserModel.create(
        acquisitionURL,
        inAppBrowserOptions
      );

      $timeout(
        function () {
          // this is a hack to fix functions not always being attached. - nik
          inAppBrowserInstance.attachFunction(
            'acpLoginHandler',
            onAcquisitionAuthentication
          );
          inAppBrowserInstance.attachFunction(
            'expiredTokenBailout',
            closeAcquisition
          );
          inAppBrowserInstance.attachFunction(
            'afEventHandler',
            onAppsflyerEvent
          );

          if (inAppBrowserOptions && inAppBrowserOptions.toolbar === false) {
            inAppBrowserInstance.attachFunction(
              'closeAcquisition',
              closeAcquisition
            );
          }
        },
        3000,
        false
      );

      inAppBrowserInstance.executeScript(
        'window.io_will_inject_fingerprint = true'
      );

      if (options.acp_access_token) {
        inAppBrowserInstance.executeScript(
          'window.acp_access_token = ' +
            ng.toJson({ token: options.acp_access_token })
        );
      }

      // For Mobile:
      // Get the iovation fingerprint, so acquisition can act on the "same device", even though
      // in a different browser.
      iovationModel.getFingerprint().then(function (fingerprint) {
        // Simply providing a string overwrites the whole document on
        // Android with the provided string. Really weird.
        var fingerprintObj = {
          fingerprint: fingerprint
        };

        // Provide the fingerprint globally
        inAppBrowserInstance.executeScript(
          'window.io_injected_fingerprint = ' + ng.toJson(fingerprintObj)
        );
      });
    }
  }

  return {
    acquireNewCustomer: function (options, inAppBrowserOptions) {
      options = options || {};
      inAppBrowserOptions = inAppBrowserOptions || {};
      acpAttributionService
        .getDeeplinkAcquisitionOptions()
        .then(function (data) {
          for (var key in data) {
            if (data.hasOwnProperty(key) && data[key]) {
              options[key] = data[key];
            }
          }
          var acquisitionURL = acpAcquisitionUrlService.decorateAcquisitionUrl(
            acpFrontendConfigModel.get(
              acpFrontendConfigModel.keys.ACQUISITION_URL
            ),
            options
          );

          openAcquisition(options, inAppBrowserOptions, acquisitionURL);
        });
    },
    getAcquisitionUrl: function (options) {
      options = options || {};

      var acquisitionURL = acpAcquisitionUrlService.decorateAcquisitionUrl(
        acpFrontendConfigModel.get(acpFrontendConfigModel.keys.ACQUISITION_URL),
        options
      );

      if (!acquisitionURL) {
        $log.warn('No acquisition URL defined');
        return;
      }

      $log.debug('Acquiring customer with', acquisitionURL);

      return acquisitionURL;
    }
  };
}

export default acpAcquisitionModel;
