import { AcpUriService, AcquisitionOptions } from 'core';

export class AcpAcquisitionUrlService {
  constructor(
    private $location: ng.ILocationService,
    private acpDeviceDetectionService: any,
    private acpUriService: AcpUriService
  ) {
    'ngInject';
  }

  public decorateAcquisitionUrl(
    acquisitionUrl: string,
    options?: AcquisitionOptions
  ): string {
    const queryStringParams: any = this.lowerCaseKeys(
      this.acpUriService.getQueryStringParameters(acquisitionUrl)
    );

    if (options && options.use_absolute_parameters) {
      delete options.use_absolute_parameters;
      for (const key in options) {
        if (options.hasOwnProperty(key)) {
          acquisitionUrl = this.acpUriService.updateQueryStringParameter(
            acquisitionUrl,
            key,
            options[key]
          );
        }
      }
      return acquisitionUrl;
    }

    // site_id is never provided without aid, so no need to update site_id without it
    if (queryStringParams.aid) {
      let siteId: string;

      if (options && options.site_id) {
        siteId = options.site_id;
      } else {
        siteId = queryStringParams.site_id;

        // Check out the definition for login.main in src/sections/login-paypal/states.js to see why I did this
        // instead of this.$state.current.url.substring(1)
        // https://jira.corp.netspend.com/browse/GB-553
        const pageName: string = this.$location
          .path()
          .split('/') // We currently set a <base> but this is still the most correct way
          .pop();

        if (siteId) {
          // Want to avoid e.g. login_login
          if (pageName !== siteId) {
            siteId = `${pageName}_${siteId}`;
          }
        } else {
          siteId = pageName;
        }

        if (
          siteId.indexOf('mobile') === -1 &&
          this.acpDeviceDetectionService.isMobile()
        ) {
          siteId += '_mobile';
        }
      }

      return this.acpUriService.updateQueryStringParameter(
        acquisitionUrl,
        'site_id',
        siteId
      );
    }

    return acquisitionUrl;
  }

  private lowerCaseKeys(params: any): any {
    const lowerCased: any = {};
    Object.keys(params).forEach((key: string) => {
      lowerCased[key.toLowerCase()] = params[key];
    });
    return lowerCased;
  }
}
