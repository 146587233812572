import { AcpAcquisitionUrlService } from './acp-aquisition-url-service';
import acpAcquisitionModel from './acp-acquisition-model';
import acpCore from 'core';
import ng from 'angular';

var component = ng.module('acp.component.acquisition-domain', [acpCore.name]);

component.factory('acpAcquisitionModel', acpAcquisitionModel);
component.service('acpAcquisitionUrlService', AcpAcquisitionUrlService);

export default component;
